import { useLocation } from 'react-router';

import { IonContent, IonPage, IonSpinner } from '@ionic/react';

import { isBurns } from '../helpers/device.helper';

const getClassName = (pathname: string) => {
  if (isBurns) {
    return;
  } else {
    switch (pathname) {
      case '/guest/services':
        return 'service-page-content';
      case '/guest/chat':
        return 'leps-page-content';
      case '/guest/alert':
        return 'fa-page-content';
    }
  }
};

export const SuspenseFallback = () => {
  const location = useLocation();

  return (
    <IonPage>
      <IonContent fullscreen className={getClassName(location.pathname)}>
        <IonSpinner className="fallback-spinner" />
      </IonContent>
    </IonPage>
  );
};
