import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';

import { IonRouterOutlet } from '@ionic/react';

import { getAnimationBuilder, isBurns, isNative } from '../helpers/device.helper';
import { AuthRoute, DesignRoute, InitialRoute } from '../helpers/router.helper';
import { useWebNotificationListener } from '../hooks';
import { useCapturePostHogPageView } from '../hooks/useCapturePostHogPageView';
import { SuspenseFallback } from './SuspenseFallback';

const AdvocateForgotPasswordPage = lazy(() => import('../pages/advocate/AdvocateForgotPassword.page'));
const AdvocateLoginPage = lazy(() => import('../pages/advocate/AdvocateLogin.page'));
const AdvocateResetPasswordPage = lazy(() => import('../pages/advocate/AdvocateResetPassword.page'));
const AdvocateTabs = lazy(() => import('./AdvocateTabs.router'));
const DesignPage = lazy(() => import('../pages/design/design.page'));
const GuestTabs = lazy(() => import('./GuestTabs.router'));
const TBWOnboardingPage = lazy(() => import('../pages/onboarding/tbw-onboarding.page'));
const SettingsPage = lazy(() => import('../pages/settings/Settings.page'));
const AboutPage = lazy(() => import('../pages/about/About.page'));
const TSLoadingPage = lazy(() => import('../pages/loading/ts-loading.page'));
const TSOnboardingPage = lazy(() => import('../pages/onboarding/ts-onboarding.page'));

const guestPaths = isNative()
  ? isBurns
    ? ['/guest/chat', '/guest/preferences', '/guest/alert']
    : ['/guest/chat', '/guest/services', '/guest/alert']
  : isBurns
    ? ['/guest/chat', '/guest/preferences']
    : ['/guest/chat', '/guest/services'];
const advocatePaths = ['/advocate/chat', '/advocate/resources', '/advocate/account', '/advocate/logout'];

export const MiddlewareRouter = () => {
  useWebNotificationListener();
  useCapturePostHogPageView();

  return (
    <Switch>
      <Route exact path={guestPaths}>
        <Suspense fallback={<SuspenseFallback />}>
          <GuestTabs />
        </Suspense>
      </Route>
      <AuthRoute exact path={advocatePaths}>
        <Suspense fallback={<SuspenseFallback />}>
          <AdvocateTabs />
        </Suspense>
      </AuthRoute>
      <IonRouterOutlet animation={getAnimationBuilder}>
        <Route exact path="/(tbw-loading|tbw-onboarding)">
          <Suspense fallback={<SuspenseFallback />}>
            <TBWOnboardingPage />
          </Suspense>
        </Route>
        <Route exact path="/ts-onboarding">
          <Suspense fallback={<SuspenseFallback />}>
            <TSOnboardingPage />
          </Suspense>
        </Route>
        <Route exact path="/ts-loading">
          <Suspense fallback={<SuspenseFallback />}>
            <TSLoadingPage />
          </Suspense>
        </Route>
        <Route exact path="/advocate-login">
          <Suspense fallback={<SuspenseFallback />}>
            <AdvocateLoginPage />
          </Suspense>
        </Route>
        <Route exact path="/advocate-forgot-password">
          <Suspense fallback={<SuspenseFallback />}>
            <AdvocateForgotPasswordPage />
          </Suspense>
        </Route>
        <Route exact path="/advocate-reset-password/:token">
          <Suspense fallback={<SuspenseFallback />}>
            <AdvocateResetPasswordPage />
          </Suspense>
        </Route>
        <Route exact path="/settings">
          <Suspense fallback={<SuspenseFallback />}>
            <SettingsPage />
          </Suspense>
        </Route>
        <Route exact path="/about">
          <Suspense fallback={<SuspenseFallback />}>
            <AboutPage />
          </Suspense>
        </Route>
        <DesignRoute exact path="/design">
          <Suspense fallback={<SuspenseFallback />}>
            <DesignPage />
          </Suspense>
        </DesignRoute>
        <InitialRoute />
      </IonRouterOutlet>
    </Switch>
  );
};
